import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ZoneValidationSchema } from "../../validations/zones";
import TextInput from "../../components/form/TextInput";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Swal from "sweetalert2";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

const EditZoneModal = ({
  show,
  setShow,
  zoneDetails,
  callGetAllZones,
  commonAPIRequest,
}) => {
  const [loading, setLoading] = useState(false);
  const { tokenData } = useContext(AuthContext);
  const updateZonePermission = tokenData?.permissions?.includes("UPDATE_ZONE");

  const formik = useFormik({
    initialValues: {
      zoneName: "",
      zoneRepName: "",
      zoneRepPhNum: "",
      zoneCoordName: "",
      zoneCoordPhNum: "",
      zoneSecyName: "",
      zoneSecyPhNum: "",
    },
    validationSchema: ZoneValidationSchema,
    onSubmit: (values) => {
      callUpdateZone(values);
    },
  });

  useEffect(() => {
    if (zoneDetails) {
      formik.setFieldValue("zoneName", zoneDetails?.zoneName);
      formik.setFieldValue("zoneRepName", zoneDetails?.zoneRepName);
      formik.setFieldValue("zoneRepPhNum", zoneDetails?.zoneRepPhNum);
      formik.setFieldValue("zoneCoordName", zoneDetails?.zoneCoordName);
      formik.setFieldValue("zoneCoordPhNum", zoneDetails?.zoneCoordPhNum);
      formik.setFieldValue("zoneSecyName", zoneDetails?.zoneSecyName);
      formik.setFieldValue("zoneSecyPhNum", zoneDetails?.zoneSecyPhNum);
    }
  }, [zoneDetails]);

  // api to update zone details start

  const callUpdateZone = (value) => {
    let serviceParams = {
      api: API.UPDATE_ZONE,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        zoneDetail: {
          zoneId: zoneDetails.zoneId,
          zoneName: value.zoneName,
          zoneRepName: value.zoneRepName,
          zoneRepPhNum: value.zoneRepPhNum,
          zoneCoordName: value.zoneCoordName,
          zoneCoordPhNum: value.zoneCoordPhNum,
          zoneSecyName: value.zoneSecyName,
          zoneSecyPhNum: value.zoneSecyPhNum,
        },
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Zone Updated Successfully.`, "success");

          callGetAllZones();
          setShow(false);
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to update zone details end

  useEffect(() => {
    if (show) {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          formik.handleSubmit();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">Edit Zone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : !updateZonePermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <div>
            <div className="mb-3">
              <TextInput
                label={"Zone Name"}
                placeholder={"St. Mathews"}
                id={"zoneName"}
                setFieldValue={formik.setFieldValue}
                keyword="zoneName"
                value={formik.values.zoneName}
                error={formik.errors.zoneName}
                touched={formik.touched.zoneName}
              />
            </div>
            <Row>
              <Col sm="6">
                <TextInput
                  label={"Zone Incharge"}
                  placeholder={"e.g. Nikhil"}
                  id={"zoneIncharge"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneRepName"
                  value={formik.values.zoneRepName}
                  error={formik.errors.zoneRepName}
                  touched={formik.touched.zoneRepName}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={" Incharge Mobile"}
                  placeholder={"e.g. 9879XXXXXX"}
                  id={"inchargeMobile"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneRepPhNum"
                  value={formik.values.zoneRepPhNum}
                  error={formik.errors.zoneRepPhNum}
                  touched={formik.touched.zoneRepPhNum}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Zone Secretary"}
                  placeholder={"e.g. Akshay"}
                  id={"zoneSecretary"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneSecyName"
                  value={formik.values.zoneSecyName}
                  error={formik.errors.zoneSecyName}
                  touched={formik.touched.zoneSecyName}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Secretary Mobile"}
                  placeholder={"e.g. 9878XXXXXX"}
                  id={"secretaryMobile"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneSecyPhNum"
                  value={formik.values.zoneSecyPhNum}
                  error={formik.errors.zoneSecyPhNum}
                  touched={formik.touched.zoneSecyPhNum}
                />
              </Col>

              <Col sm="6">
                <TextInput
                  label={"Zone Coordinator"}
                  placeholder={"e.g. Akshay"}
                  id={"zoneCoordinator"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneCoordName"
                  value={formik.values.zoneCoordName}
                  error={formik.errors.zoneCoordName}
                  touched={formik.touched.zoneCoordName}
                />
              </Col>
              <Col sm="6">
                <TextInput
                  label={"Coordinator Mobile"}
                  placeholder={"e.g. 9878XXXXXX"}
                  id={"coordinatorMobile"}
                  setFieldValue={formik.setFieldValue}
                  keyword="zoneCoordPhNum"
                  value={formik.values.zoneCoordPhNum}
                  error={formik.errors.zoneCoordPhNum}
                  touched={formik.touched.zoneCoordPhNum}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
      {updateZonePermission && !loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditZoneModal);
