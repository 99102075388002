import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Modal, Placeholder } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AXIOS_METHOD_TYPES, API } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import AddSubZonesModal from "./AddSubZonesModal";
import EditSubZonesModal from "./EditSubZonesModal";
import CustomTooltip from "../../components/CustomTooltip";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

const ViewSubZonesModal = ({
  show,
  setShow,
  zoneDetails,
  commonAPIRequest,
}) => {
  const [subZones, setSubZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subZoneModalVisible, setSubZoneModalVisible] = useState(false);
  let navigate = useNavigate();
  const [editSubzoneModalVisible, setEditSubzoneModalVisible] = useState(false);
  const [currentSubZone, setCurrentSubZone] = useState();
  const { tokenData } = useContext(AuthContext);
  const readSubZonePermission =
    tokenData?.permissions?.includes("READ_SUBZONE");

  const callGetSubZones = () => {
    let serviceParams = {
      api: `${API.GET_ALL_SUB_ZONES}?zoneDetailId=${zoneDetails?.zoneDetails?.zoneId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setSubZones(
            result?.payload.map((subZone) => {
              return {
                label: subZone.address,
                value: subZone.subZoneId,
              };
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (zoneDetails?.zoneDetails?.zoneId && show) {
      callGetSubZones();
    }
  }, [zoneDetails?.zoneDetails?.zoneId, show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Subzones Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!readSubZonePermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated noMinHeight />
          </div>
        ) : (
          <Card className="card-one justify-content-end">
            <Card.Header
              style={{ justifyContent: "space-between" }}
              className="py-0"
            >
              <Card.Title as="h5">
                All Subzones in <br />{" "}
                <span className="fw-bold" style={{ fontSize: 24 }}>
                  {zoneDetails?.label}
                </span>
              </Card.Title>
              <CustomTooltip label={"Add Subzone"} placement={"top"}>
                <Button onClick={() => setSubZoneModalVisible(true)}>
                  <i style={{ fontSize: 24 }} className="ri-add-line"></i>
                </Button>
              </CustomTooltip>
            </Card.Header>
            <hr className="hr" />
            <Card.Body
              style={{ maxHeight: 250, overflowY: "auto" }}
              className="p-0"
            >
              {subZones.length === 0 ? (
                !loading && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 150, height: 150 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h4 className="text-center fw-light">No Subzones Found</h4>
                  </div>
                )
              ) : (
                <ul className="people-group">
                  {subZones.map((item, index) =>
                    loading ? (
                      <Placeholder as="p" animation="glow">
                        <Placeholder xs="7" />
                        <Placeholder xs="4" />
                        <Placeholder xs="6" />
                      </Placeholder>
                    ) : (
                      <li className="people-item" key={index}>
                        <div className="avatar">
                          <span className={"avatar-initial fs-20 bg-dark"}>
                            <i className={"ri-map-pin-line"}></i>
                          </span>
                        </div>
                        <div className="people-body">
                          <h6>{item.label}</h6>
                        </div>
                        <div className="text-end">
                          <CustomTooltip
                            label={"View Families"}
                            placement={"top"}
                          >
                            <Button
                              className="btn-dark"
                              onClick={() => {
                                navigate("/admin/sub-zones-families", {
                                  state: {
                                    subZoneId: item?.value,
                                    subZoneName: item.label,
                                  },
                                });
                              }}
                            >
                              <i className={"ri-team-line"}></i>
                            </Button>
                          </CustomTooltip>
                          <CustomTooltip
                            label={"Edit Subzone"}
                            placement={"top"}
                          >
                            <Button
                              className="mx-1 btn-dark"
                              onClick={() => {
                                setEditSubzoneModalVisible(true);
                                setCurrentSubZone({
                                  subZoneId: item?.value,
                                  subZoneName: item.label,
                                });
                              }}
                            >
                              <i className="ri-edit-box-line"></i>
                            </Button>
                          </CustomTooltip>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </Card.Body>
            <Card.Footer className="d-flex justify-content-center"></Card.Footer>
          </Card>
        )}
      </Modal.Body>
      <AddSubZonesModal
        show={subZoneModalVisible}
        setShow={setSubZoneModalVisible}
        zoneId={zoneDetails?.zoneDetails?.zoneId}
        zoneName={zoneDetails?.zoneDetails?.zoneName}
        callGetSubZones={callGetSubZones}
      />
      <EditSubZonesModal
        show={editSubzoneModalVisible}
        setShow={setEditSubzoneModalVisible}
        subZone={currentSubZone}
        callGetSubZones={callGetSubZones}
      />
    </Modal>
  );
};

export default withAPIRequest(ViewSubZonesModal);
