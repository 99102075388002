import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { HolyCommunionValidationSchema } from "../../validations/holy-communion";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";
import TextArea from "../../components/form/TextArea";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";

function capitalizeText(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const MakeExtToIntHolyCommunionModal = ({
  show,
  setShow,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  commonAPIRequest,
  closeOffset,
  baptismDetails,
  contactDetails,
  familyDetails,
  currentHolyCommunion,
}) => {
  const [loading, setLoading] = useState(false);
  const [baptismName, setBaptismName] = useState(true);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      dob: "",
      gender: "",
      dateOfBaptism: "",
      holyCommunionDate: "",
      address: "",
      mobileNum: "",
      extHolyCommunion: false,
      baptismDateForValidation: "",
      registerRefNumber: null,
      holyCommunionDateNotSure: false,
    },
    validationSchema: HolyCommunionValidationSchema,
    onSubmit: (values) => {
      Swal.fire({
        title: "<strong>Are you sure?</strong>",
        icon: "warning",
        html: `
        <dl>
          <dt>Date Of Baptism</dt>
          <dd>${new Date(formik.values?.dateOfBaptism).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
          <dt>Date Of Holy Communion </dt>
          <dd>${new Date(values?.holyCommunionDate).toLocaleDateString(
            "en-GB",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          )}</dd>
        </dl>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `Yes, Sure!`,
        confirmButtonAriaLabel: "Yes, Sure!",
        cancelButtonText: `No`,
        cancelButtonAriaLabel: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          callEditHolyCommunionDetailsApi(values);
        }
      });
    },
  });

  // function to call API for Add New Holy Communion start

  const callEditHolyCommunionDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_HOLY_COMMUNION_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        holyCommunionDetail: {
          holyCommunionId: currentHolyCommunion?.holyCommunionId,
          firstName: capitalizeText(formik.values?.firstName.trim()),
          middleName: capitalizeText(formik.values?.middleName.trim()),
          lastName: capitalizeText(formik.values?.lastName.trim()),
          baptismDate: new Date(formik.values.dateOfBaptism),
          dob: new Date(formik.values.dob),
          gender: formik.values?.gender,
          fatherName: capitalizeText(formik.values?.fatherName.trim()),
          motherName: capitalizeText(formik.values?.motherName.trim()),
          holyCommunionDate: new Date(formik.values.holyCommunionDate),
          address: formik.values?.address.trim(),
          mobileNum: formik.values?.mobileNum.trim(),
          regReferenceNum: formik.values?.registerRefNumber
            ? +formik.values?.registerRefNumber
            : null,
          holyCommunionDateAccurate: !formik.values.holyCommunionDateNotSure,
          extHolyCommunion: false,
          extHolyCommunionChurch: null,
          extHolyCommunionCity: null,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Holy Communion Created Successfully.`,
            "success"
          );
          getFamilyMemberDetails();
          setShow(false);
          formik.resetForm();
          closeOffset();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Add New Holy Communion end

  useEffect(() => {
    if (show) {
      if (basicDetails?.baptismName) {
        formik.setFieldValue("firstName", basicDetails?.baptismName);
        formik.setFieldValue("middleName", "");
        formik.setFieldValue("lastName", "");
      } else {
        formik.setFieldValue("firstName", basicDetails?.firstName);
        formik.setFieldValue("middleName", basicDetails?.middleName);
        formik.setFieldValue("lastName", basicDetails?.lastName);
      }

      formik.setFieldValue("fatherName", basicDetails?.fatherName);
      formik.setFieldValue("motherName", basicDetails?.motherName);
      formik.setFieldValue("dob", new Date(basicDetails?.dateOfBirth));
      formik.setFieldValue("gender", basicDetails?.gender);
      formik.setFieldValue(
        "dateOfBaptism",
        new Date(baptismDetails?.baptismDate)
      );
      formik.setFieldValue(
        "baptismDateForValidation",
        new Date(
          new Date(baptismDetails?.baptismDate).setDate(
            new Date(baptismDetails?.baptismDate).getDate() - 1
          )
        )
      );
      formik.setFieldValue("mobileNum", contactDetails?.phNumber);
      formik.setFieldValue("address", familyDetails?.address?.addressLine1);
      if (baptismDetails?.holyCommunionDate) {
        formik.setFieldValue(
          "holyCommunionDate",
          new Date(baptismDetails?.holyCommunionDate)
        );
      }
    }
  }, [show]);

  useEffect(() => {
    if (baptismName) {
      if (basicDetails?.baptismName) {
        formik.setFieldValue("firstName", basicDetails?.baptismName);
        formik.setFieldValue("middleName", "");
        formik.setFieldValue("lastName", "");
      }
    } else {
      formik.setFieldValue("firstName", basicDetails?.firstName);
      formik.setFieldValue(
        "middleName",
        basicDetails?.middleName ? basicDetails?.middleName : ""
      );
      formik.setFieldValue(
        "lastName",
        basicDetails?.lastName ? basicDetails?.lastName : ""
      );
    }
  }, [baptismName]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Make Holy Communion Certificate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          <Row className="g-3">
            <Col sm={!baptismName ? 4 : 12}>
              <TextInput
                label={baptismName ? "Baptism Name" : "First Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"firstName"}
                value={formik.values.firstName}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
                disabled={basicDetails?.firstName ? true : false}
              />
              {basicDetails?.baptismName && (
                <Form.Check
                  type="switch"
                  className="mt-1"
                  checked={baptismName}
                  label="Use Baptism Name"
                  onChange={(e) => {
                    setBaptismName(e.target.checked);
                  }}
                />
              )}
            </Col>
            {!baptismName && (
              <Col sm={4}>
                <TextInput
                  label={"Middle Name"}
                  setFieldValue={formik.setFieldValue}
                  keyword={"middleName"}
                  value={formik.values.middleName}
                  error={formik.errors.middleName}
                  touched={formik.touched.middleName}
                  disabled={true}
                />
              </Col>
            )}
            {!baptismName && (
              <Col sm={4}>
                <TextInput
                  label={"Last Name"}
                  setFieldValue={formik.setFieldValue}
                  keyword={"lastName"}
                  value={formik.values.lastName}
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                  disabled={true}
                />
              </Col>
            )}
            <Col sm={6}>
              <TextInput
                label={"Father's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"fatherName"}
                value={formik.values.fatherName}
                error={formik.errors.fatherName}
                touched={formik.touched.fatherName}
                // disabled={basicDetails?.fatherName ? true : false}
                disabled
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Mother's Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"motherName"}
                value={formik.values.motherName}
                error={formik.errors.motherName}
                touched={formik.touched.motherName}
                // disabled={basicDetails?.motherName ? true : false}
                disabled
              />
            </Col>
            <Col sm={6}>
              <DateInput
                label={"Date Of Birth"}
                setFieldValue={formik.setFieldValue}
                keyword={"dob"}
                value={formik.values.dob}
                error={formik.errors.dob}
                touched={formik.touched.dob}
                disabled={basicDetails?.dateOfBirth ? true : false}
              />
            </Col>
            <Col sm={6}>
              <SelectInput
                label={"Gender"}
                selectOptions={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                setFieldValue={formik.setFieldValue}
                keyword={"gender"}
                value={formik.values.gender}
                error={formik.errors.gender}
                touched={formik.touched.gender}
                disabled={basicDetails?.gender ? true : false}
              />
            </Col>
            <Col sm={6}>
              <DateInput
                label={"Date Of Baptism"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfBaptism"}
                value={formik.values.dateOfBaptism}
                error={formik.errors.dateOfBaptism}
                touched={formik.touched.dateOfBaptism}
                disabled={baptismDetails?.baptismDate ? true : false}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Mobile Number"}
                setFieldValue={formik.setFieldValue}
                keyword={"mobileNum"}
                value={formik.values.mobileNum}
                error={formik.errors.mobileNum}
                touched={formik.touched.mobileNum}
              />
            </Col>

            <Col sm={12}>
              <DateInput
                label={"Date Of Holy Communion"}
                setFieldValue={formik.setFieldValue}
                keyword={"holyCommunionDate"}
                value={formik.values.holyCommunionDate}
                error={formik.errors.holyCommunionDate}
                touched={formik.touched.holyCommunionDate}
              />
            </Col>
            <Col className="mt-2" sm={12}>
              <Form.Check
                type="checkbox"
                label="Not sure if holy communion date is correct"
                onChange={(e) => {
                  formik.setFieldValue(
                    "holyCommunionDateNotSure",
                    e.target.checked
                  );
                }}
                checked={formik.values.holyCommunionDateNotSure}
              />
            </Col>

            <Col>
              <TextArea
                label={"Residential Address"}
                setFieldValue={formik.setFieldValue}
                keyword={"address"}
                value={formik.values.address}
                error={formik.errors.address}
                touched={formik.touched.address}
              />
            </Col>
            <Col sm={12}>
              <TextInput
                label={"Register Reference Number"}
                setFieldValue={formik.setFieldValue}
                keyword={"registerRefNumber"}
                value={formik.values.registerRefNumber}
                error={formik.errors.registerRefNumber}
                touched={formik.touched.registerRefNumber}
                type={"number"}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(MakeExtToIntHolyCommunionModal);
